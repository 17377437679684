import { useMediaQuery } from '@material-ui/core';
import { UserProfileFollowButton } from 'components';
import { SchoolTestResultsIcon } from 'components/icons';
import React, { memo } from 'react';
import { from } from 'styles/mixins';
import { formatMonthNameAndYear } from 'utils/dates';
import { pluralize } from 'utils/pluralize';
import { useLogic } from './logic';
import {
  AddressCardIcon,
  BookIcon,
  Container,
  FollowContainer,
  FollowCounter,
  FollowInfo,
  FollowInfoContainer,
  PersonalWebsiteLink,
  PodcastIcon,
  ProfileLink,
  SectionContainer,
  SectionSubtitle,
  SectionText,
  SectionTitle,
  SocialLink,
  SocialLinks,
  SpotlightIcon,
  SwdActionsContainer,
} from './styles';
import { Props } from './types';

const About: React.FC<Props> = ({
  user,
  isLoggedIn,
  meUserId,
  onToggleFollowUser,
}) => {
  const isMobile = !useMediaQuery(from.tabletH.query);
  const {
    dateJoined,
    bio: description = '',
    goals = '',
    nBooks: books,
    wAttended,
    podcastFollower: subscribedToPodcast,
    spotlight,
    socialWeb: website,
    instagramURL: instagramUrl,
    socialLinkedin: linkedIn,
    twitterURL: twitterUrl,
    courses,
    followersCount,
    followingCount,
  } = user;
  const spotlightUrl = spotlight && `/conversations/${spotlight.id}`;
  const nCourses = courses.length;

  const showBooks = books > 0;
  const showWAttend = wAttended;
  const showSubscribedToPodcast = subscribedToPodcast;
  const showCourses = nCourses > 0;
  const noContentSwdActions =
    !showBooks &&
    !showWAttend &&
    !showSubscribedToPodcast &&
    !spotlightUrl &&
    !showCourses;

  const noContentLinks = !website && !instagramUrl && !linkedIn && !twitterUrl;

  const onClickProfileFollowButton = () => onToggleFollowUser?.(!user.followed);

  const { handleOpenFollowModal } = useLogic();

  return (
    <Container>
      <FollowContainer>
        <FollowInfoContainer>
          <FollowInfo
            onClick={() =>
              handleOpenFollowModal({
                id: user.id,
                slug: user.slug,
                selectedTab: 'followers',
              })
            }
          >
            <span>FOLLOWERS</span>
            <FollowCounter>{followersCount}</FollowCounter>
          </FollowInfo>
          <FollowInfo
            onClick={() =>
              handleOpenFollowModal({
                id: user.id,
                slug: user.slug,
                selectedTab: 'followings',
              })
            }
          >
            <span>FOLLOWING</span>
            <FollowCounter>{followingCount}</FollowCounter>
          </FollowInfo>
          {!isMobile && isLoggedIn && user.id !== meUserId ? (
            <UserProfileFollowButton
              followed={!!user.followed}
              onClick={onClickProfileFollowButton}
            />
          ) : null}
        </FollowInfoContainer>
      </FollowContainer>

      <SectionContainer>
        <SectionTitle>About</SectionTitle>

        <SectionSubtitle>
          Member since {formatMonthNameAndYear(dateJoined)}
        </SectionSubtitle>

        {description ? <SectionText>{description}</SectionText> : null}
      </SectionContainer>

      {goals ? (
        <SectionContainer>
          <SectionTitle>Current goals</SectionTitle>

          <SectionText>{goals}</SectionText>
        </SectionContainer>
      ) : null}

      {!noContentSwdActions ? (
        <SectionContainer>
          <SectionTitle>SWD Actions</SectionTitle>

          <SwdActionsContainer>
            {showBooks && (
              <ProfileLink
                href="http://www.storytellingwithdata.com/books"
                target="__blank"
              >
                <BookIcon />
                {books} {pluralize(books, 'Book', 'Books')}
              </ProfileLink>
            )}

            {showWAttend && (
              <ProfileLink
                href="http://www.storytellingwithdata.com/public-workshops"
                target="__blank"
              >
                <AddressCardIcon />
                Attended SWD workshop
              </ProfileLink>
            )}

            {showSubscribedToPodcast && (
              <ProfileLink
                href="http://www.storytellingwithdata.com/podcast"
                target="__blank"
              >
                <PodcastIcon />
                Subscribed to podcast
              </ProfileLink>
            )}

            {spotlightUrl && (
              <ProfileLink href={spotlightUrl}>
                <SpotlightIcon />
                Member spotlight
              </ProfileLink>
            )}

            {showCourses && (
              <ProfileLink>
                <SchoolTestResultsIcon />
                SWD Scholar
              </ProfileLink>
            )}
          </SwdActionsContainer>
        </SectionContainer>
      ) : null}

      {!noContentLinks ? (
        <SectionContainer>
          <SectionTitle>Links</SectionTitle>

          <SocialLinks>
            {website && (
              <PersonalWebsiteLink to={website} rel="noopener" target="__blank">
                {website}
              </PersonalWebsiteLink>
            )}

            {instagramUrl && (
              <SocialLink
                to={instagramUrl}
                rel="noreferrer noopener"
                target="__blank"
              >
                Instagram
              </SocialLink>
            )}

            {linkedIn && (
              <SocialLink
                to={linkedIn}
                rel="noreferrer noopener"
                target="__blank"
              >
                LinkedIn
              </SocialLink>
            )}

            {twitterUrl && (
              <SocialLink
                to={twitterUrl}
                rel="noreferrer noopener"
                target="__blank"
              >
                Twitter
              </SocialLink>
            )}
          </SocialLinks>
        </SectionContainer>
      ) : null}
    </Container>
  );
};

export default memo(About);
