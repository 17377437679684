import DefaultLink from 'components/Link';
import styled, { css } from 'styled-components';
import { buttonReset } from 'styles/mixins';
import { addAlpha } from 'utils/addAlpha';
import { Props } from './types';

const buttonVariants = css<{ mode: Props['mode']; variant: Props['variant'] }>`
  ${buttonReset};
  align-items: center;
  border-radius: 0.25rem;
  cursor: pointer;
  display: inline-flex;
  font-weight: ${({ theme }) => theme.weights.bold};
  font-size: 0.875rem;
  justify-content: center;
  line-height: 1;
  letter-spacing: 1px;
  padding: 0.8125rem 1.25rem;
  text-transform: uppercase;
  white-space: nowrap;

  :active {
    opacity: 0.8;
  }

  :disabled {
    cursor: initial;
  }

  ${({ variant }) =>
    (variant === 'primary' &&
      css`
        background-color: ${({ theme }) => theme.colors.accent};
        color: ${({ theme }) => theme.colors.white};
        border: solid 1px ${({ theme }) => theme.colors.accent};

        :disabled {
          background-color: ${({ theme }) => theme.colors.sky};
        }
        :hover:not(:disabled) {
          background-color: ${({ theme }) => theme.colors.primaryHover};
          box-shadow: 0 2px 15px -3px ${({ theme }) => addAlpha(theme.colors.black, 0.4)};
        }
      `) ||
    (variant === 'secondary' &&
      css`
        background-color: ${({ theme }) => theme.colors.white};
        border: solid 1px ${({ theme }) => theme.colors.greyBlue};
        color: ${({ theme }) => theme.colors.accent};

        :disabled {
          color: ${({ theme }) => theme.colors.sky};
        }

        :hover:not(:disabled) {
          box-shadow: 0 2px 8px -3px ${({ theme }) => addAlpha(theme.colors.black, 0.4)};
          border-color: ${({ theme }) => theme.colors.accent};
        }
      `) ||
    (variant === 'danger' &&
      css`
        background-color: ${({ theme }) => theme.colors.error};
        border: solid 1px ${({ theme }) => theme.colors.error};
        color: ${({ theme }) => theme.colors.white};

        :disabled {
          opacity: 0.5;
        }

        :hover:not(:disabled) {
          background-color: ${({ theme }) => theme.colors.red};
          border-color: ${({ theme }) => theme.colors.red};
          box-shadow: 0 2px 15px -3px ${({ theme }) => addAlpha(theme.colors.black, 0.4)};
        }
      `) ||
    (variant === 'link' &&
      css`
        color: ${({ theme }) => theme.colors.accent};
        margin-left: ${({ theme }) => theme.spacing.md};
        transition: color 0.5s ease;

        :hover {
          color: ${({ theme }) => theme.colors.primary};
        }
      `) ||
    (variant === 'premiumAccent' &&
      css`
        background-color: ${({ theme }) => theme.colors.basicGold};
        color: ${({ theme }) => theme.colors.judge};
        margin-top: 2.125rem;
        width: fit-content;
        :hover:not(:disabled) {
          background-color: ${({ theme }) => theme.colors.peach};
          box-shadow: 0 2px 15px -3px rgba(88, 78, 54, 0.4);
        }
        :disabled {
          background-color: ${({ theme }) => theme.colors.peach};
          color: ${({ theme }) => theme.colors.donkey};
          box-shadow: 0 2px 15px -3px rgba(88, 78, 54, 0.4);
        }
      `) ||
    (variant === 'premiumSecondary' &&
      css`
        background-color: ${({ theme }) => theme.colors.primary};
        color: ${({ theme }) => theme.colors.white};
        margin-top: 2.125rem;
        width: fit-content;
        :hover:not(:disabled) {
          background-color: ${({ theme }) => theme.colors.darkPrimary};
          box-shadow: 0 2px 15px -3px rgba(73, 80, 87, 0.4);
        }
        :disabled {
          background-color: ${({ theme }) => theme.colors.darkPrimary};
          box-shadow: 0 2px 15px -3px rgba(73, 80, 87, 0.4);
          color: ${({ theme }) => addAlpha(theme.colors.white, 0.8)};
        }
      `)}

  ${({ mode }) =>
    (mode === 'big' &&
      css`
        font-size: 1.125rem;
        padding: 1rem 1rem;
        line-height: 0.78;
        letter-spacing: 1.29px;
      `) ||
    (mode === 'small' &&
      css`
        padding: 0.5rem 0.5rem;
      `)}
`;

export const ButtonDefault = styled.button`
  ${buttonVariants};
`;

export const Link = styled(DefaultLink)`
  ${buttonVariants};
`;
