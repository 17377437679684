import React, { memo } from 'react';
import { Button } from './styles';

const UserProfileFollowButton = ({
  followed,
  className,
  onClick,
}: {
  followed: boolean;
  className?: string;
  onClick: (e: React.MouseEvent) => void;
}) => {
  return (
    <Button
      variant={followed ? 'secondary' : 'primary'}
      className={className}
      onClick={onClick}
    >
      {followed ? <>Unfollow</> : <>Follow</>}
    </Button>
  );
};

export default memo(UserProfileFollowButton);
